@import "atoms/variables";@import "functions";
.backgroundLeft {
  background-image: url('/images/background/logos.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.registrar {
  display: flex;
  justify-content: center;

  & > div {
    & > h2,
    h1 {
      align-items: center;
      justify-content: center;
      display: flex;
      color: $blue-primary-700;
      font-family: $main-font;
      font-size: rem(36px);
      text-align: center;
      @media (max-width: $mobile-width) {
        font-size: 20.25px;
        line-height: 24px;
      }
    }
    & > div {
      display: flex;
      justify-content: center;

      & > p {
        font-family: $main-font;
        font-size: 20px;
        line-height: 34px;
        text-align: center;
        color: $gray-scale-900;
        width: 80%;

        @media (max-width: $mobile-width) {
          width: 100%;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}
.indicadores {
  display: flex;
  justify-content: center;

  & > div {
    padding: 4rem;

    @media (max-width: $mobile-width) {
      padding: 1rem;
    }

    & > h2 {
      text-align: center;
      color: $blue-primary-700;
      font-family: $main-font;
      font-size: 2rem;
    }

    & > .header {
      & > p {
        font-family: $main-font;
        text-align: center;
        font-size: 18px;
        line-height: 22px;
        color: $gray-scale-900;
      }
    }

    & > .content {
      padding-top: 1rem;
      display: grid;
      grid-template-columns: auto auto auto auto;
      gap: 20px;

      @media (max-width: $mobile-width) {
        grid-template-columns: auto auto;
      }

      & > div {
        width: 100%;

        & > h4,
        h3 {
          font-family: $main-font;
          text-align: center;
          font-weight: 700;
          font-size: 50px;
          line-height: 62px;
        }
        & > p {
          font-family: $main-font;
          text-align: center;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          letter-spacing: -0.0025em;
        }
      }
    }
  }
}

.background {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(1, 19, 116, 0.35), rgba(1, 19, 116, 0.35)),
    #2a84c5;
}

.negociosHome {
  .hero {
    background-image: url('/images/hero-negocios.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: rem(580px);
    position: relative;
    width: 100%;

    @media (max-width: $mobile-width) {
      height: auto;
    }

    .content {
      left: 10%;
      max-width: rem(740px);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width: $mobile-width) {
        display: flex;
        flex-direction: column;
        padding: rem(56px) rem(16px) rem(50px);
        position: relative;
        left: 0;
        top: 0;
        transform: none;
      }

      h2,
      p {
        color: $white;
      }

      h2 {
        font-size: rem(72px);
        margin: 0 0 rem(48px);

        @media (max-width: $mobile-width) {
          font-size: rem(40px);
        }
      }

      p {
        font-size: rem(25px);
        margin-bottom: rem(48px);

        @media (max-width: $mobile-width) {
          font-size: rem(18px);
          margin-bottom: rem(32px);
        }
      }

      a {
        display: inline-block;
        padding: rem(18px) rem(48px);

        @media (max-width: $mobile-width) {
          align-self: center;
          padding: rem(16px);
        }
      }
    }
  }
}

.headerx > div {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 5px 25px;
}
.headerx > div > div:last-child {
  display: flex;
  align-items: center;
}
.headerx a {
  display: inline-block;
}
.headerx img {
  width: 132px;
  height: 50px;
}
button.blue,
a.blue {
  font-weight: 600;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  align-items: center;
  background-image: linear-gradient(90deg, #233e7f 0%, #3f69ca 100%);
  border: 1px solid #3153a3;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}
button.yellow,
a.yellow {
  font-weight: 600;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 20px;
  color: #1a2f60;
  align-items: center;
  background-color: #fab84c;
  border: 1px solid #fab84c;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}
a.blue,
a.yellow {
  text-decoration: none;
}
.hero-image {
  width: 100%;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
}
.hero-text {
  background-image: linear-gradient(90deg, #233e7f 0%, #3f69ca 100%);
  border-radius: 10px;
}
.hero-text .h1 {
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-shadow: -2.1px 1.05px 1.05px #000000;
  margin-bottom: 30px;
}
.container-1 {
  width: 100%;
}
.container-1 > div {
  margin: 0 auto;
}
.container-1 .h1 {
  font-weight: bold;
  color: #233e7f;
  text-align: center;
}
.container-1 p {
  text-align: center;
  color: #24252e;
  text-align: center;
}
.container-1 .angle-right {
  width: 20px;
  height: 10px;
}
.grid-1 .thumbnail {
  background-color: #ffffff;
  border: 1px solid #d3d4dd;
  box-shadow: 0px 2px 1px rgba(108, 108, 108, 0.25);
  border-radius: 5px;
}
.grid-1 .thumbnail .img {
  width: 80%;
  background-image: url('/images/svg/photographer.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 15px;
  display: flex;
  align-items: end;
}
.grid-1 .thumbnail a {
  display: block;
  text-decoration: none;
}
.grid-1 .thumbnail .img .tag {
  width: 130px;
  height: 22px;
  background-color: #d9fde9;
  border: 1px solid #79f6b3;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  //background-color: $primary-blue;
  padding: rem(6px);
  & > p {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #24252e;
  }
  .date {
    align-items: center;
    border-radius: rem(5px);
    display: inline-flex;
    background-color: $yellow-300;
    padding: rem(4px) rem(36px);

    & > p {
      color: $primary-blue;
      font-weight: 700;

      &:first-child {
        font-size: rem(20px);
        margin-right: rem(4px);
      }

      &:last-child {
        text-transform: capitalize;
      }
    }
  }
}
.imageContainer {
  height: rem(150px);
  position: relative;
  width: 100%;
  overflow: hidden;

  @media (min-width: $mobile-width) {
    height: rem(175px);
  }

  & > img {
    width: 100%;
  }
}
.grid-1 .thumbnail .img .tag > *:not(:last-child) {
  margin-right: 5px;
}
.grid-1 .thumbnail .img img {
  width: 15px;
  height: 15px;
}
.grid-1 .thumbnail .img span {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #043a1d;
}
.grid-1 .thumbnail .content {
  width: 100%;
  padding: 15px;
}
.grid-1 .thumbnail .content h4,
.grid-1 .thumbnail .content h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #24252e;
}
.grid-1 .thumbnail .content p {
  font-size: 16px;
  line-height: 20px;
  color: #24252e;
}

.grid-1 .thumbnail .content h3 {
  width: 260px;

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 20.25px;

  margin-bottom: 20px;
  align-items: center;
}
.grid-1 .thumbnail .content h4,
.grid-1 .thumbnail .content h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #24252e;
}
.grid-1 .thumbnail .content > *:not(:last-child) {
  margin-bottom: 10px;
}
.grid-1 .thumbnail .flex {
  display: flex;
  justify-content: space-between;
}
.container-2 > div {
  margin: 0 auto;
}
.container-2 h2 {
  font-weight: bold;
  color: #233e7f;
  text-align: center;
  @media (max-width: $mobile-width) {
    font-size: rem(20px);
  }
}
.container-2 img {
  width: 100%;
}
.container-2 ol {
  list-style-type: none;
}
.container-2 ol li:not(:last-child) {
  margin-bottom: 25px;
}
.container-2 ol h4,
.container-2 ol h3 {
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: #24252e;
  display: inline-block;
  margin-bottom: 10px;
  @media (max-width: $mobile-width) {
    font-size: rem(16px);
    line-height: 20px;
    font-weight: 500;
  }
}
.container-2 ol h4::before,
.container-2 ol h3::before {
  counter-increment: section;
  content: counter(section) '. ';
}
.container-2 ol p {
  font-size: 18px;
  line-height: 22px;
  color: #24252e;
  @media (max-width: $mobile-width) {
    font-size: rem(16px);
    line-height: 20px;
    font-weight: 400;
  }
}
.container-3 > div {
  width: 92%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 25px;
}
.container-3 > div > div:first-child {
  display: flex;
}
.container-3 h3 {
  font-weight: 600;
  color: #233e7f;
  text-align: center;
}
.container-3 img {
  width: 100%;
}
.container-3 h2 {
  font-weight: 600;
  color: #233e7f;
  margin-bottom: 25px;
}
.container-3 p {
  font-size: 18px;
  line-height: 22px;
  color: #24252e;
  margin-bottom: 30px;
}
.container-3 ul {
  list-style-type: none;
}
.container-3 ul li {
  display: flex;
  align-items: center;
}
.container-3 ul > li:not(:last-child) {
  margin-bottom: 20px;
}
.container-3 ul li > *:not(:last-child) {
  margin-right: 10px;
}
.container-3 ul li span {
  font-weight: 600;
  line-height: 59px;
  color: #24252e;
  white-space: nowrap;
}
.container-3 ul li h4,
.container-3 ul li h3 {
  color: #24252e;
}
.container-4 > div {
  width: 92%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 25px;

  & > h4,
  h3 {
    font-family: $main-font;
    color: $blue-primary-700;
    font-weight: 500;
    font-size: 33px;
    line-height: 41px;
    align-items: center;
    letter-spacing: -0.0025em;
    text-align: center;
    width: 100%;
  }
}
.container-4 > div {
  display: flex;
}
.container-4 img {
  width: 100%;
}
.container-4 ul {
  list-style-type: none;
}
.container-4 ul > li:not(:last-child) {
  margin-bottom: 25px;
}
.container-4 ul li h3 {
  font-weight: 500;
  line-height: 41px;
  color: #233e7f;
  margin-bottom: 10px;
}
.container-4 ul li p {
  font-size: 18px;
  line-height: 22px;
  color: #24252e;
}
.container-4 ul li b {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #24252e;
}
.container-4 ul li div {
  margin-top: 30px;
}
.container-5 > div {
  margin: 0 auto;
}
.container-5 h2 {
  font-weight: 600;
  line-height: 46px;
  color: #233e7f;
}
.container-5 .flex {
  display: flex;
}
.container-5 .flex > div {
  display: flex;
}
.container-5 .flex > div > *:not(:last-child) {
  margin-right: 30px;
}
.container-5 .flex img {
  width: 80px;
  height: 80px;
}
.container-5 .flex b {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #24252e;
  margin-bottom: 5px;
  display: block;
}
.container-5 .flex p {
  font-size: 16px;
  line-height: 20px;
  color: #24252e;
}
.company-grid {
  padding-top: 25px;
  padding-bottom: 25px;
}
.scroll-to-top {
  display: flex;
  justify-content: end;
  margin-bottom: 25px;
}
.scroll-to-top > button {
  width: 36px;
  height: 36px;
  background-color: #f0f0f5;
  border: 1px solid #233e7f;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.scroll-to-top > button > img {
  width: 15px;
}
.footerx {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #ffffff;
}
.footerx > div {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footerx > div > div {
  width: 50%;
}
.footerx > div > div:first-child {
  display: flex;
  justify-content: space-between;
}
.footerx .left img {
  width: 150px;
  height: 180px;
}
.footerx .left ul {
  list-style-type: none;
  margin-bottom: 25px;
}
.footerx .left ul li:not(:last-child) {
  margin-bottom: 15px;
}
.footerx .left ul > li > a {
  font-weight: 600;
  font-size: 15px;
  color: #1b629d;
  text-decoration: none;
}
.footerx .social .text,
.footerx .app .text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #24252e;
  margin-bottom: 16px;
}
.footerx .social .social-list {
  list-style-type: none;
  display: flex;
  width: 100%;
  align-items: center;
}
.footerx .social .social-list > *:not(:last-child) {
  margin-right: 20px;
}
.footerx .social .social-list a {
  align-items: center;
  display: inline-flex;
}
.footerx .social .social-list img {
  width: 25px;
  height: 25px;
}
.footerx .app {
  margin-bottom: 50px;
}
.footerx .app .app-list {
  list-style-type: none;
}
.footerx .app .app-list > li:not(:last-child) {
  margin-bottom: 15px;
}
.footerx .app .app-list a {
  display: inline-flex;
}
.footerx .app .app-list img {
  height: 45px;
}
.copyright {
  padding: 8px 0px;
  background-image: linear-gradient(90deg, #9fcd49 0%, #259a49 100%);
}
.copyright > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #24252e;
  text-align: center;
}
@media (max-width: 1024px) {
  .hero-image {
    background-image: url('/images/svg/photographer.jpg');
    padding-left: 16px;
    padding-right: 16px;
    align-items: start;
    padding-top: 30px;
  }
  .hero-text {
    padding: 8px;
  }
  .hero-text .h1 {
    font-size: 28px;
  }
  .grid-1 > *:not(:last-child) {
    margin-bottom: 25px;
  }
  .grid-1 .thumbnail .img {
    height: 200px;
  }
  .container-1 .h1 {
    font-size: 22px;
    line-height: 26px;
  }
  .container-1 > div > *:not(:last-child) {
    margin-bottom: 25px;
  }
  .container-2 h2 {
    font-size: 22px;
    margin-bottom: 30px;
  }
  .container-1 > div,
  .container-2 > div {
    width: 90%;
  }
  .container-2 > div > *:not(:last-child) {
    margin-bottom: 40px;
  }
  .container-3 ul li span {
    font-size: 24px;
  }
  .container-3 > div,
  .container-4 > div > div {
    padding-left: 15px;
    padding-right: 15px;
  }
  .container-3 > div > div:first-child > div:first-child {
    order: 2;
    display: flex;
    flex-direction: column;
  }
  .container-3 ul {
    margin-bottom: 35px;
  }
  .container-3 h2 {
    text-align: center;
  }
  .container-1,
  .container-2,
  .container-3,
  .container-4,
  .container-5,
  .container-4 > div,
  .container-3 > div {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .container-1 p,
  .container-3 h3,
  .container-4 ul li h3,
  .container-3 h2,
  .container-3 ul li h4,
  .container-3 ul li h3 {
    font-size: 16px;
  }
  .container-5 h2 {
    font-size: 20px;
    margin-bottom: 35px;
  }
  .container-5 > div {
    width: 90%;
  }
  .container-5 > div > div > *:not(:last-child),
  .container-5 .flex > *:not(:last-child) {
    margin-bottom: 35px;
  }
  .scroll-to-top {
    width: 90%;
  }
  .company-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 95%;
    margin: 0 auto;
    row-gap: 25px;
  }
  .company-grid > div {
    display: flex;
    justify-content: center;
  }
  .company-grid img {
    width: 100px;
    height: 50px;
  }
  .footerx > div,
  .container-3 > div > div:first-child,
  .container-5 .flex,
  .container-4 > div {
    flex-direction: column;
  }
  .footerx > div > div:first-child {
    flex-direction: column;
    width: 100%;
  }
  .footerx > div > div:first-child > *:not(:last-child) {
    margin-bottom: 30px;
  }
  .footerx > div > div:last-child {
    width: 100%;
  }
  .footerx .app,
  .footerx .left a.yellow {
    display: none;
  }
  .footerx .left ul li {
    text-align: center;
  }
  .footerx > div > div:first-child > :not(:last-child) {
    text-align: center;
  }
  .footerx > div > div:last-child {
    justify-content: center;
  }
  .footerx .social .social-list {
    justify-content: space-between;
  }
  .footerx .social .text,
  .footerx .app .text {
    text-align: center;
  }
}
@media (min-width: 1025px) {
  .hero-image {
    background-image: url('/images/svg/photographer.jpg');
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
  }
  .hero-text {
    width: 691px;
    padding: 40px 48px;
    height: 292px;
  }
  .hero-text .h1 {
    font-size: 48px;
  }
  .grid-1 {
    display: flex;
  }
  .grid-1 .thumbnail .img {
    height: 151px;
  }
  .grid-1 > *:not(:last-child) {
    margin-right: 50px;
  }
  .container-1 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .container-1 .h1 {
    font-size: 36px;
    line-height: 46px;
  }
  .container-1 p {
    font-size: 24px;
    line-height: 34px;
  }
  .container-1 > div {
    width: 80%;
  }
  .container-1 > div > *:not(:last-child) {
    margin-bottom: 40px;
  }
  .container-2 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .container-2 > div {
    display: flex;
    width: 85%;
  }
  .container-2 > div > div:first-child {
    width: 60%;
    margin-right: 35px;
  }
  .container-2 > div > div:last-child {
    width: 40%;
  }
  .container-2 h2 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 60px;
  }
  .container-3 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .container-3 ul li h4,
  .container-3 ul li h3 {
    font-size: 25px;
    line-height: 32px;
  }
  .container-3 h2 {
    font-size: 32px;
    line-height: 41px;
  }
  .container-3 h3 {
    font-size: 25px;
    line-height: 32px;
  }
  .container-3 > div {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 128px;
    padding-right: 24px;
  }
  .container-3 > div > div > div:first-child {
    width: 50%;
    margin-right: 35px;
  }
  .container-3 ul li span {
    font-size: 50px;
  }
  .container-4 > div {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 100px;
    padding-right: 100px;
  }
  .container-4 > div > div:last-child,
  .container-3 > div > div > div:last-child {
    width: 50%;
  }
  .container-4 ul li h3 {
    font-size: 32px;
  }
  .container-4 > div > div:first-child {
    width: 50%;
    margin-right: 35px;
  }
  .container-5 > div {
    width: 70%;
  }
  .container-5 .flex {
    justify-content: space-between;
  }
  .container-5 .flex > div {
    width: 400px;
  }
  .container-4,
  .container-5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .container-5 h2 {
    font-size: 36px;
    margin-bottom: 70px;
    text-align: center;
  }
  .container-5 > div > div > *:not(:last-child) {
    margin-bottom: 70px;
  }
  .scroll-to-top {
    padding-left: 50px;
    padding-right: 50px;
  }
  .company-grid {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .company-grid img {
    width: 140px;
    height: 80px;
  }
  .footerx > div > div:last-child {
    display: flex;
    justify-content: end;
  }
}

.BenefitsSection {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > div {
    display: flex;
    margin: 0 auto;
    flex-direction: row;

    @media (max-width: $mobile-width) {
      flex-direction: column-reverse;
      justify-content: center;

      & > div {
        width: 80%;
        margin-left: 1rem;
      }
    }

    & > img {
      width: rem(449px);
      height: rem(880px);
      margin-left: rem(80px);

      @media (max-width: $mobile-width) {
        width: 80%;
        height: auto;
        margin-left: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }

  & > h2 {
    width: 90vh;
    font-weight: normal;
    color: #233e7f;
    text-align: center;
    margin-bottom: rem(80px);
    font-size: 36px;
    @media (max-width: $mobile-width) {
      width: 100%;
      font-size: 20.25px;
      line-height: 24px;
      font-weight: 700;
    }
  }

  .BenefitsItem {
    display: flex;
    max-width: rem(520px);
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    margin-bottom: rem(20px);

    .InfoItem {
      max-width: 432px;

      & > h4,
      h3 {
        font-size: 18px;
        font-weight: 500;
      }

      & > p {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}

.EventSection {
  width: 100%;
  display: flex;
  overflow-x: scroll; /* Evita que el scroll sea bloqueado */
  -webkit-overflow-scrolling: touch; /* Suavizar desplazamiento en móviles */


  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > button {
    margin-top: rem(60px);
    margin-bottom: rem(68px);
    height: rem(64px);
  }

  & > div {
    display: flex;
    margin: 0 auto;
    flex-direction: row;
  }

  & > h2 {
    font-weight: bold;
    color: #233e7f;
    text-align: center;
    margin-bottom: rem(40px);
    text-align: center;
    font-size: 36px;
  }

  & > p {
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    width: 801px;
    margin-bottom: 80px;
    @media (max-width: $mobile-width) {
      width: 100%;
    }
  }

  .GridEvent {
    display: flex;
    gap: rem(32px);
    padding: 0 40px;
    overflow-x: auto;

    

    .thumbnail {
      width: rem(340px);
      background-color: #ffffff;
      border: 1px solid #d3d4dd;
      box-shadow: 0px 2px 1px rgba(108, 108, 108, 0.25);
      border-radius: 5px;

      .contentImg {
        margin: rem(24px);

        .tag {
          background-color: #233e7f;
          height: 49px;
          display: flex;
          align-items: center;
          border-radius: 5px 5px 0 0;

          & > div {
            height: 33px;
            width: 133px;
            background-color: #fbc774;
            margin: 8px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              color: #233e7f;
            }
          }
        }

        .thumbnailImg {
          background-image: url('/images/svg/photographer.jpg');
          height: 153px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          padding: 15px;
          display: flex;
          align-items: end;
          border-radius: 0 0 5px 5px;
        }
      }

      .content {
        margin: 0 rem(24px) rem(24px) rem(24px);

        h4,
        h3 {
          color: #098b45;
        }

        p {
          font-size: rem(15px);
        }

        & > article:last-child {
          margin-top: rem(8px);

          & > h4,
          h3 {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.ExperienceSection {
  width: 92%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 25px;
  display: flex;
  padding: rem(85px);
  gap: rem(80px);

  @media (max-width: $mobile-width) {
    padding: rem(16px);
    display: block;
  }

  h2 {
    width: 80vh;
    font-weight: normal;
    color: #233e7f;
    margin-bottom: rem(50px);
    font-size: 32px;
    @media (max-width: $mobile-width) {
      width: 100%;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
  }

  p {
    width: 80vh;
    margin-bottom: rem(32px);
    @media (max-width: $mobile-width) {
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }

  h4,
  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  .virtualCard {
    width: rem(328px);
    padding: 8px;
    border: 1px solid #d3d4dd;
    box-shadow: 0px 2px 1px rgba(108, 108, 108, 0.25);
    border-radius: 5px;

    .virtualCardTitle {
      background-color: #233e7f;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px 5px 0 0;
      color: #ffffff;
      text-align: center;
    }

    .imgVirtualCard {
      background-image: url('/images/vr.png');
      height: 153px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      padding: 15px;
      display: flex;
      align-items: end;
      border-radius: 0 0 5px 5px;
      margin-bottom: 20px;
    }
  }
}

.location {
  a {
    &:first-child {
      margin-right: 5px;

      &:not(:last-child):after {
        content: ',';
      }
    }

    &:nth-child(2) {
      margin-right: 5px;
      &:not(:last-child):after {
        content: ',';
      }
    }
    &:nth-child(3) {
      margin-right: 5px;
      &:not(:last-child):after {
        content: ',';
      }
    }
  }
}
