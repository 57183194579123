// Color Scales
// Blue Scale
$blue-primary-50: #dfe6f6;
$blue-primary-100: #bfcded;
$blue-primary-200: #9fb4e5;
$blue-primary-300: #6f8ed8;
$blue-primary-400: #4f75cf;
$blue-primary-500: #355ec0;
$blue-primary-600: #2c4fa0;
$blue-primary-700: #233e7f;
$blue-primary-800: #1a2f60;
$blue-primary-900: #0d1830;

// Green Scale
$green-50: #d9fde9;
$green-100: #9ff9c9;
$green-200: #79f6b3;
$green-300: #3ff393;
$green-400: #19f07d;
$green-500: #0dd36a;
$green-600: #0bad56;
$green-700: #098b45;
$green-800: #066030;
$green-900: #043a1d;

// Yellow Scale
$yellow-50: #fef7eb;
$yellow-100: #fde7c3;
$yellow-200: #fcd79c;
$yellow-300: #fbc774;
$yellow-400: #fab84c;
$yellow-500: #f9a720;
$yellow-600: #ef9606;
$yellow-700: #c77d05;
$yellow-800: #9f6404;
$yellow-900: #764a04;

// Red Scale
$red-50: #fadcdd;
$red-100: #f7cacc;
$red-200: #f2a6aa;
$red-300: #ea7177;
$red-400: #e44e55;
$red-500: #da2129;
$red-600: #c31d25;
$red-700: #a0181f;
$red-800: #7c1318;
$red-900: #580e11;

// Cyan Scale
$cyan-50: #dcecf9;
$cyan-100: #a8d0f0;
$cyan-200: #86bdea;
$cyan-300: #52a0e0;
$cyan-400: #2f8dda;
$cyan-500: #2174ba;
$cyan-600: #1b629d;
$cyan-700: #154c79;
$cyan-800: #0c2c45;
$cyan-900: #061623;

// Gray Scale
$gray-scale-50: #f7f7f7;
$gray-scale-100: #f0f0f5;
$gray-scale-200: #d3d4dd;
$gray-scale-300: #aaaab9;
$gray-scale-400: #8f8fa2;
$gray-scale-500: #7a7b8e;
$gray-scale-600: #696979;
$gray-scale-700: #515162;
$gray-scale-800: #3a3a47;
$gray-scale-900: #24252e;

//COP 16
$cop16-primary-green: #008172;

// Gradients
$gradient-blue-primary: linear-gradient(90deg, #233e7f 0%, #3f69ca 100%);
$gradient-blue-primary-reverse: linear-gradient(
  90deg,
  #3f69ca 0%,
  #233e7f 100%
);
$gradient-blue-secondary: linear-gradient(90deg, #315da8 0%, #6aaee1 100%);
$gradient-green-primary: linear-gradient(90deg, #2a641f 0%, #5cc943 100%);
$gradient-green-secondary: linear-gradient(90deg, #259a49 0%, #9fcd49 100%);
$gradient-yellow-secondary: linear-gradient(90deg, #ee8e24 0%, #f8de4f 100%);
$gradient-red-secondary: linear-gradient(90deg, #d92028 0%, #de6732 100%);
$gradient-gray-primary: linear-gradient(90deg, #d3d4dd 0%, #f0f0f5 100%);

// Main Colors
$primary-blue: $blue-primary-700;
$primary-green: $green-700;
$primary-yellow: $yellow-500;
$primary-red: $red-500;
$primary-cyan: $cyan-500;

$semantic-info: #0091da;
$semantic-success: #05944f;
$semantic-success-2: $green-900;
$semantic-warning: #ffa400;
$semantic-warning-2: #764a04;
$semantic-error: #da291c;
$semantic-error-2: $red-700;
$semantic-links: $cyan-600;

$background-grey: #f2f2f2;
$grey-dark-1: #363636;
$text-grey: #4f4f4f;
$white: #ffffff;
$black: #000000;

// Social Colors
$facebook-color: #1778f2;
$youtube-color: #ff0000;
$instagram-color: $primary-blue;
$twitter-color: #55acee;

$experiencia-mistica-color: #ffa71a;
$herencia-cultural-color: #e14127;
$paraiso-litoral-color: #2a84c5;
$travesia-natural-color: #4ea943;

// Fonts
$main-font: 'Quicksand', sans-serif;

// Size
$base: 16px;

$mobile-width: 800px;
$max-content-width: 1195px;
